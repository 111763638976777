import React from 'react';
import logo from './image/background.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <div className="example">
      <img src={logo} />
      <p>{"Welcome!!\nCosmos Athlete!!"}</p>
      </div>
      </header>
    </div>
  );
}

export default App;
